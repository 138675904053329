@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap");

:root {
  --offset-y: 0px;
  --offset-x: 0px;
}

body,
h1 {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
  color: transparent;
}

body {
  background: white;
}

h1.front {
  /* animation: police 3.14s ease-in-out infinite; */
  transition: transform 0.1s linear;
  color: yellow;
  text-shadow: 2px 2px 0 black, -2px 2px 0 black, 2px -2px 0 black,
    -2px -2px 0 black;
}

@keyframes police {
  0%,
  100% {
    color: black;
    text-shadow: 2px 2px 0 yellow, -2px 2px 0 yellow, 2px -2px 0 yellow,
      -2px -2px 0 yellow;
  }
  50% {
    color: yellow;
    text-shadow: 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black,
      -1px -1px 0 black;
  }
}
